:host {
 
    font-size: 1.3rem;
    text-align: center; 
    width: 100%;
    color: var(--color--dark-text);
    padding: 2rem 0;
}


:host([emphasize=""]),:host([emphasize="true"]){
    background: var(--surface--color--secondary--dark);
    color: var(--color--light-text);
}


div {
 
    width: 600px;
    max-width: 90%;
    margin: auto;
}
::slotted(h2) {
    font-size: 2rem;
    font-weight: 500;
}

::slotted(p) {
    font-weight: 200;
}




